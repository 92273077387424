<template>
  <div class="reset_container">
    <div class="reset_form">
      <div class="reset_form_container">
        <div class="reset">
          <div class="reset_pass_form">
            <div class="reset_label">{{ $t('Reset_password') }}</div>
            <div class="reset_input_pass_container">
              <input :type="showPass ? 'text' : 'password'" v-model="password" class="reset_input_pass"
                     :placeholder="$t('New_Password')"/>
              <div class="reset_input_pass_open" v-show="showPass" @click="showPass = false"/>
              <div class="reset_input_pass_close" v-show="!showPass" @click="showPass = true"/>
              <span v-if="$v.password.$dirty && $v.password.$invalid"
                    class="text-danger">{{ $t('Password_is_required!_At_least_8_characters') }}.</span>
            </div>
            <div class="reset_input_pass_container">
              <input :type="showNewPass ? 'text' : 'password'" v-model="passwordNew" class="reset_input_pass"
                     :placeholder="$t('Repeat_password')"/>
              <div class="reset_input_pass_open" v-show="showNewPass" @click="showNewPass = false"/>
              <div class="reset_input_pass_close" v-show="!showNewPass" @click="showNewPass = true"/>
              <span v-if="this.password !== this.passwordNew" class="text-danger">{{
                  $t('Passwords_must_match')
                }}!</span>
            </div>
            <div class="reset_btn_next" @click="resetPass">
              <span>{{ $t('Save') }}</span>
            </div>
            <router-link href="/" class="reset_on_main">{{ $t('Go_back_to_the_main_page') }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import LoginForm from '@/components/LoginForm.vue';
import {minLength, required} from "vuelidate/lib/validators";

export default {
  name: 'ResetPassword',
  components: {
    LoginForm
  },
  data() {
    return {
      showPass: false,
      password: "",
      showNewPass: false,
      passwordNew: "",
    }
  },
  methods: {
    resetPass() {
      let postData = {
        token: this.$route.query.token,
        email: localStorage.getItem('email'),
        password: this.password,
        password_confirmation: this.passwordNew,
      }

      this.$v.$touch()
      if (this.$v.password.$invalid) return

      this.$http.post(API_ROOT + `/api/auth/email/restore`, postData)
        .then((res) => {
          console.log(res)
          Vue.toastr({
            message: this.$t('Success'),
            description: res.body.message,
            type: 'success'
          })
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          localStorage.removeItem('email')
          this.$router.push({name: 'signin'})
        })
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(8)
    },
    passwordNew: {
      required,
      minLength: minLength(8)
    }
  },

}
</script>
<style>

</style>
